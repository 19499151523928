<template>
    <v-card flat tile>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-col cols='12'>
            <v-tabs
                v-model="tab"
                grow
            >
                <v-tab>
                    <v-icon>mdi-format-list-checks</v-icon> 
                    <span class='d-none d-sm-block'>Listado de </span> 
                    <span class='ml-1'> tareas</span>   
                </v-tab>
                <v-tab>
                    <v-icon>mdi-chart-arc</v-icon> Indicadores
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item :key="`tab-list-task-${tenantId}`" >
                    <v-toolbar dense>
                        <v-icon>mdi-filter</v-icon>
                        <v-toolbar-title class='mr-5'>Filtros</v-toolbar-title>
                        <v-switch
                            class='mt-6 mx-3'
                            v-model=own
                            :label="own ? 'Tareas tomadas' : 'Tareas de grupos'"
                            @change="getTask"
                        />
                        <v-radio-group
                            v-model="status"
                            row
                            class='mt-6 mx-3'
                        >
                            <v-radio
                                label="ACTIVAS"
                                value="CAL_TAR_PLANIFICADO"
                                @click="getTask"
                            ></v-radio>
                            <v-radio
                                label="COMPLETADAS"
                                value="CAL_TAR_COMPLETADO"
                                @click="getTask"
                            ></v-radio>
                            <v-radio
                                label="RECHAZADAS"
                                value="CAL_TAR_RECHAZADO"
                                @click="getTask"
                            ></v-radio>
                        </v-radio-group>
                    </v-toolbar>
                    <v-list-item-group>
                        <v-list-item 
                            v-for="(we, index) in workEfforts"
                            :key=index
                            class='mt-1 grey lighten-3'
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                <v-list-item-title>{{we.type_work_effort}} #{{we.work_effort_id}} </v-list-item-title>
                                <v-list-item-subtitle><b>Tipo:</b> {{we.work_effort_purpose_type}}</v-list-item-subtitle>
                                <v-list-item-subtitle><b>Descripción:</b> {{we.description}}</v-list-item-subtitle>
                                <v-list-item-subtitle><b>Creación:</b> {{we.created_date}}</v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="we.current_status_id == 'CAL_TAR_RECHAZADO'"
                                >
                                    <b>Resultado:</b> {{we.resultados}}
                                </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn 
                                        color="blue-grey lighten-2" 
                                        class='white--text mt-3'
                                        v-if="we.party_id_assign != we.party_id"
                                        @click="takeTask(we)"
                                    >
                                        <v-icon >mdi-hand-pointing-right</v-icon>
                                        TOMAR TAREA
                                    </v-btn>
                                    <v-btn 
                                        color="green lighten-2" 
                                        class='white--text mt-5'
                                        v-else
                                        :to=we.content
                                        target="_blank"
                                    >
                                        <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
                                        IR A LA TAREA
                                    </v-btn>
                                </v-list-item-icon>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                    <v-alert
                        v-if=!workEfforts.length
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                    >
                        SIN TAREAS
                    </v-alert>
                    <v-pagination
                        class='mt-10'
                        v-model=currentPage
                        :length=totalItems
                        @input="handlePageChange"
                        total-visible="15"
                    ></v-pagination>
                </v-tab-item>
                <v-tab-item :key="`tab-indicators-${tenantId}`">
                    <superAdminIncatorsComponents />
                </v-tab-item>
            </v-tabs-items>
       </v-col>
    </v-card>
</template>

<script>

import superAdminIncatorsComponents from '../../components/dashboard/SuperAdminIndicatorsComponent'
import { mapState, mapMutations, mapActions } from 'vuex'
import Vue from 'vue'

export default {
    
    name:'IndexComponent',
    components:{
        superAdminIncatorsComponents
    },
    data: ()=>({
        fav:false,
        message: false,
        hints: true,
        own:true,
        tab:null,
        workEfforts:[],
        status: 'CAL_TAR_PLANIFICADO',
        overlay:false,
        totalItems:1,
        currentPage:1,
        itemsPerPage:10,
    }),
    watch:{
        tenantId: function() {
            this.getTask()
        }
    },
    computed:{

        ...mapState('master',['tenantId','user','urlWs']),

        ...mapState('access',['loggedIn']),

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingBtn']),

        ...mapActions('master',['requestApi','alertNotification']),

        handlePageChange(value) {
            this.currentPage = value
            this.getTask()
        },

        getTask(){
            
            this.setUrl('user-tareas')
            this.overlay=true
            this.requestApi({
				method: 'GET',
				data: {
                    own: this.own,
                    page:this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    status: this.status
                }
			}).then(res =>{

                this.totalItems= res.data.page_count
                this.workEfforts = res.data._embedded.user_tareas
                   
			}).then(()=>{
                
                this.overlay=false
                
            })

        },

        takeTask(we){
        
            Vue.swal({
            html: "Está seguro de tomar esta tarea?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {
                    
                    const {
                        work_effort_id,
                        party_id_assign
                    } = we

                    this.setUrl('user-tareas')

                    this.requestApi({
                        method: 'PUT',
                        data: {
                            work_effort_id,
                            party_id_assign
                        }
                    }).then(res =>{
                        console.log(res)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })
                       
                        
                    }).then(()=>{
                        this.getTask()
                    })
        
                }
            })
        },

    },
    mounted(){
        this.getTask()
    }

}
</script>
